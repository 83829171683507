<template>
    <div class="titleTabs">
        <div class="header">
            <div v-for="(tab, index) in tabsData" :key="index" @click="toPage(index)" 
                :class="[currentIndex===index?'active': '']">
                <h4><img :src="tab.imgURL" alt=""></h4>
                <span class="title">{{tab.title}}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TitleTabs',

    props: ['tabsData'],

    data() {
        return {
            currentIndex: 0
        };
    },

    mounted() {
        
    },

    methods: {
        toPage(index) {
            this.currentIndex = index
            this.$emit("changeTab", index)
        }
    },
};
</script>

<style lang="less" scoped>
    .titleTabs {
        margin: 30px auto;
        padding: 20px;
        border-bottom: 2px solid #ccc;
        .header {
            display: flex;
            justify-content: space-evenly;
            img {
                width: 50px;
                height: 50px;
                vertical-align: middle;
            }
            div {
                padding: 5px;
                width: 130px;
                align-items: center;
                display: flex;
                justify-content: space-between;
                h4 {
                    margin-right: 5px;
                }
                span {
                    font-weight: 800;
                }
                &.active {
                    border-bottom: 2px solid #1890ff;
                }
                &:hover {
                    border-bottom: 2px solid #1890ff;
                }
            }
        }
    }
</style>