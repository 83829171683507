<template>
    <div class="introduce">
        <div class="header">产品介绍</div>
        <p>{{introduce.content}}</p>
        <div class="msg" v-for="(item, index) in introduce.imgURLs" :key="index">
            <img :src="item" alt="" :style="{width:(introduce.changeImg?'650px':'')}">
        </div>

        <div class="download">
            <a @click="changeDownload">免费试用</a>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Introduce',
    props: ['introduce'],

    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        changeDownload() {
            this.$emit('changeDownload')
        }
    },
};
</script>

<style lang="less" scoped>
    .introduce {
        .header {
            font-size: 20px;
            font-weight: 700;
        }
        p {
            text-indent: 2em;
        }
        .msg {
            text-align: center;
            margin-top: 10px;
            img {
                // width: 600px;
                width:700px;
            }
        }
        .download {
            display: flex;
            justify-content: center;
            margin-top: 15px;
            margin-bottom: 30px;
            a {
                height: 40px;
                width: 120px;
                text-align: center;
                line-height: 40px;
                color: #fff;
                background-color: #68b6ff;
                border-radius: 40px;
                cursor: pointer;
                &:hover {
                    background-color: #1890ff;
                }
            }
        }
    }
</style>