<template>
    <div class="Breadcrumb">
        <div class="breadcrumb wapper">
            <ul>
                <li><a href="javaScript:;" @click="toHome" class="home">首页</a></li>
                <li><a href="#" class="home">></a></li>
                <li><a href="javaScript:;" @click="toOrder" class="home">产品介绍</a></li>
                <li><a href="#" class="home">></a></li>
                <li><a href="javaScript:;">{{currentPage}}</a></li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Breadcrumb',

    props: ['currentPage'],

    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        toOrder() {
            this.$router.replace('/Product')
        },

        toHome() {
            this.$router.replace('/Home')
        }
    },
};
</script>

<style lang="less" scoped>
    .breadcrumb {
        padding: 10px 20px;
        margin: 20px auto;
        border-bottom: 1px solid rgba(0,0,0,.06);
        ul {
            display: flex;
            li {
                margin-right: 8px;
                .home {
                    color: rgba(0,0,0,.45);
                }
            }
        }
    }
</style>