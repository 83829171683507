<template>
    <div class="Download">
        <div class="downloadBtn">
            <div class="logo">
                <img :src="downloadData.imgURL" alt="">
                <h2 class="title">{{downloadData.title}}</h2>
            </div>
            <a :href="downloadData.downloadURl">立即下载</a>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Download',

    props: ["downloadData"],

    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
};
</script>

<style lang="less" scoped>
    .downloadBtn {
        margin: 30px 0;
        display: flex;
        margin-left: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        .logo {
            margin-right: 20px;
            text-align: center;
            img {
                width: 50px;
                height: 50px;
            }
            .title {
                font-size: 16px;
            }
        }
        a {
            height: 40px;
            width: 120px;
            text-align: center;
            line-height: 40px;
            color: #fff;
            background-color: #68b6ff;
            border-radius: 40px;
            &:hover {
                background-color: #1890ff;
            }
        }
    }
</style>