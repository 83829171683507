<template>
    <div class="Problem">
        <div class="title" v-if="problemData.title">{{problemData.title}}</div>
        <dl v-for="(problem, index) in problemData.problemArr" :key="index">
            <dt class="problem">
                <div class="top">
                    <span class="bg" :style="{width:(problem.length>100?'70px': '28px')}"></span>
                    <div class="problemItem">{{problem}}</div> 
                </div>
                <img v-for="(item, index) in problemData.problemImgs[index]" :key="index"
                    :src="item" alt="" style="margin: 5px 0;">
            </dt>

            <dd class="answer">
                <div class="top">
                    <span class="bg" :style="{width:(problemData.answerArr[index].length>90&&!problemData.longAnswerArr?'45px':'30px')}"></span>
                    <div class="answerItem" v-if="problemData.answerArr[index].length<100">{{problemData.answerArr[index]}}</div> 

                    <div class="nav" v-else>
                        <div class="longTitle">
                            <h3>{{problemData.longAnswerArr[index].title}}</h3>
                            <ul>
                                <li v-for="(content, index) in problemData.longAnswerArr[index].contents" :key="index">{{content}}</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <img  v-for="(item, index) in problemData.answerImgs[index]" :key="index"
                    :src="item" alt="">
            </dd>
        </dl>
    </div>
</template>

<script>
export default {
    name: 'OfficialWebsiteIndex',

    props: ["problemData"],

    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
};
</script>

<style lang="less" scoped>
    .Problem {
        .title {
            color: orange;
        }
        // padding: 0 20px 20px;
        dl {
            padding: 20px;
            // margin: 10px 0;
            border-bottom: 2px solid #ccc;
            dt {
                // max-height: 20px;
                .top {
                    display: flex;
                    .bg {
                        display: inline-block;
                        width: 25px;
                        height: 35px;
                        background-size: contain;
                        background-image: url('./uploads/weng.png');
                        background-repeat: no-repeat;
                    }
                    .problemItem {
                        margin-bottom: 5px;
                        margin-left: 10px;
                    }
                }
                
                img {
                    width: 100%;
                    // margin: 5px 0;
                }
            }
            dd {
                .top {
                    display: flex;
                    .bg {
                        display: inline-block;
                        width: 30px;
                        height: 35px;
                        background-size: contain;
                        background-image: url('./uploads/da.png');
                        background-repeat: no-repeat;
                    }
                    .answerItem {
                        margin-left: 10px;
                    }
                    .nav {
                        .longTitle {
                            margin-left: 9px;
                            h3{
                                font-weight: normal;
                                font-size: 16px;
                            }
                            li {
                                text-indent: 2em;
                            }
                        }
                    }
                }
                img {
                    width: 100%;
                    margin: 10px 0;
                }
            }
        
        }
    }
</style>