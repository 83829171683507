<template>
    <div class="tabItems">
        <ul class="nav">
            <li v-for="(item, index) in manageArr" :class="[tabCurrentIndex===index?'active': '']"
                :key="index" @click="toItemPage(index)">
                <span class="bg"></span>
                <span class="title">{{item}}</span>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'TabItems',
    props: ['tabCurrentIndex'],

    data() {
        return {
            manageArr: ['产品介绍', '使用教程', '常见问题', '软件下载'],
            // tabCurrentIndex: 0,
        };
    },

    mounted() {
        
    },

    methods: {
        toItemPage(index) {
            this.$emit('changeItem', index)
        }
    },
};
</script>

<style lang="less" scoped>
    .tabItems{
        margin: 30px auto;
        .nav {
            width: 95%;
            margin: 0 auto;
            display: flex;
            padding: 20px;
            li {
                width: 25%;
                height: 80px;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: space-evenly;
                border: 1px solid #eee;
                font-weight: 700;
                .bg {
                        width: 30px;
                        height: 30px;
                        background-size: cover;
                }
                &:nth-child(1) .bg{
                    background-image: url("./uploads/introduce.png");
                }
                &:nth-child(2) .bg{
                    background-image: url("./uploads/teach.png");
                }
                &:nth-child(3) .bg{
                    background-image: url("./uploads/problem.png");
                }
                &:nth-child(4) .bg{
                    background-image: url("./uploads/download.png");
                }
                &:nth-child(1):hover {
                    color: #fff;
                    background-color: #1890ff;
                    .bg {
                        background-image: url("./uploads/introduceH.png");
                    }
                }
                &:nth-child(2):hover {
                    color: #fff;
                    background-color: #1890ff;
                    .bg {
                        background-image: url("./uploads/teachH.png");
                    }
                }
                &:nth-child(3):hover {
                    color: #fff;
                    background-color: #1890ff;
                    .bg {
                        background-image: url("./uploads/problemH.png");
                    }
                }
                &:nth-child(4):hover {
                    color: #fff;
                    background-color: #1890ff;
                    .bg {
                        background-image: url("./uploads/downloadH.png");
                    }
                }

                &:nth-child(1).active {
                    color: #fff;
                    background-color: #1890ff;
                    .bg {
                        background-image: url("./uploads/introduceH.png");
                    }
                }

                &:nth-child(2).active {
                    color: #fff;
                    background-color: #1890ff;
                    .bg {
                        background-image: url("./uploads/teachH.png");
                    }
                }
                &:nth-child(3).active {
                    color: #fff;
                    background-color: #1890ff;
                    .bg {
                        background-image: url("./uploads/problemH.png");
                    }
                }
                &:nth-child(4).active {
                    color: #fff;
                    background-color: #1890ff;
                    .bg {
                        background-image: url("./uploads/downloadH.png");
                    }
                }
            }   
        }
    }
</style>