<template>
    <div class="teach">
        <ul>
            <li v-for="(item, index) in teachData.imgURLs" :key="index">
                <span class="title" v-if="teachData.imgTitle">{{teachData.imgTitle[index]}}</span>
                <span class="title" v-else>{{index+1+"."}}</span>

                <div class="imgInfo" >
                    <img :src="item" alt="" :style="{height: (teachData.changeImg&&index===1?'70%': '')}">
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'Teach',

    props: ['teachData'],

    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
};
</script>

<style lang="less" scoped>
    .teach {
        ul{
            li {
                display: flex;
                border-bottom: 1px solid #ccc;
                flex-direction: column;
                padding-left: 20px;
                padding-bottom: 25px;
                text-align: center;
                margin-bottom: 25px;
                .title {
                    font-weight: 700;
                    font-size: 22px;
                    width: 17%;
                    text-align: left;
                }
                .imgInfo {
                    width: 85%;
                    margin-left: 35px;
                    img {
                        width: 95%;
                    }
                }
                &:last-child {
                    margin-bottom: none;
                }
            }
        }
    }
    
</style>